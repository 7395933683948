import React, { useMemo, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useWebTrader } from 'utils/WebTraderProvider';
import { fetchTrades } from 'redux/trade/tradeSlice';
import { useDispatch } from 'react-redux';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& > table > tbody > tr:nth-child(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& > table > tbody > tr > td, & > table > tbody > tr > th': {
    borderBottom: 'unset',
  },
}));

const getTradeWithMarketData = (trade, marketData) => {
  const liveData = marketData[trade.conid];
  console.log('Processing trade:', trade.symbol, 'with market data:', liveData);
  
  // Initialize with trade's last_price if available
  let last = Number(trade.last_price) || 0;
  let chgPercent = 0;  // We don't store change percent in trade model
  
  // Update with live market data if available
  if (liveData) {
    last = Number(liveData.bid_price) || last;  // Fallback to trade's last_price if live data is 0
    chgPercent = Number(liveData.change_percent) || 0;
  }

  const qty = Number(trade.quantity) || 0;
  const avgFill = Number(trade.average_entry_price) || 0;
  
  if (last === 0) {
    return {
      ...trade,
      last: '-',
      'chg%': '-',
      'openP/L': '-',
      mktValue: '-',
    };
  }

  const isLong = trade.trade_type === 'LONG';
  const mktValue = last * Math.abs(qty);
  const costBasis = avgFill * Math.abs(qty);
  const openPL = isLong ? mktValue - costBasis : costBasis - mktValue;

  return {
    ...trade,
    last,
    'chg%': chgPercent,
    'openP/L': openPL,
    mktValue,
  };
};

const PositionsTable = ({ trades = [], strategyId, isActive }) => {
  const dispatch = useDispatch();
  const { marketData } = useWebTrader();

  useEffect(() => {
    console.log('Market Data updated:', marketData);
    console.log('Current trades:', trades);
  }, [marketData, trades]);

  const enrichedTrades = useMemo(() => {
    console.log('Recalculating enriched trades');
    return trades.map(trade => getTradeWithMarketData(trade, marketData));
  }, [trades, marketData]);

  useEffect(() => {
    if (!isActive) return;

    // Initial fetch with refresh
    dispatch(fetchTrades({ strategyId, refresh: true }));

    // Set up polling interval
    const intervalId = setInterval(() => {
      dispatch(fetchTrades({ strategyId, refresh: true }));
    }, 5000);

    return () => clearInterval(intervalId);
  }, [dispatch, strategyId, isActive]);

  return (
    <StyledTableContainer component={Paper} sx={{ mt: 1 }}>
      <Table size="small" aria-label="positions table">
        <TableHead>
          <TableRow>
            <TableCell>Symbol</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Avg Entry</TableCell>
            <TableCell>Last</TableCell>
            <TableCell>Chg%</TableCell>
            <TableCell>Open P/L</TableCell>
            <TableCell>Mkt Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enrichedTrades.length > 0 ? (
            enrichedTrades.map((trade) => (
              <TableRow key={trade.id}>
                <TableCell>{trade.symbol}</TableCell>
                <TableCell>{trade.trade_type}</TableCell>
                <TableCell>{trade.status}</TableCell>
                <TableCell>{trade.quantity}</TableCell>
                <TableCell>{Number(trade.average_entry_price)?.toFixed(2) || '-'}</TableCell>
                <TableCell>
                  {typeof trade.last === 'number' 
                    ? trade.last.toFixed(2) 
                    : trade.last || '-'}
                </TableCell>
                <TableCell>
                  {typeof trade['chg%'] === 'number' 
                    ? `${trade['chg%'].toFixed(2)}%` 
                    : trade['chg%'] || '-'}
                </TableCell>
                <TableCell 
                  sx={{ 
                    color: typeof trade['openP/L'] === 'number'
                      ? (trade['openP/L'] > 0 
                        ? 'success.dark' 
                        : trade['openP/L'] < 0 
                          ? 'error.main' 
                          : 'inherit')
                      : 'inherit'
                  }}
                >
                  {typeof trade['openP/L'] === 'number'
                    ? trade['openP/L'].toFixed(2)
                    : trade['openP/L'] || '-'}
                </TableCell>
                <TableCell>
                  {typeof trade.mktValue === 'number'
                    ? trade.mktValue.toFixed(2)
                    : trade.mktValue || '-'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                No active trades found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default PositionsTable;
