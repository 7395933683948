import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import webSocketManager from './WebSocketManager';
import { selectActiveTrades } from 'redux/trade/tradeSlice';

const WebTraderContext = createContext();

export function WebTraderProvider({ children, initialSymbol }) {
  const activeTrades = useSelector(selectActiveTrades);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  
  const [marketData, setMarketData] = useState({});
  const [subscribedConids, setSubscribedConids] = useState([]);
  
  // Order modification state
  const [isModifyingOrder, setIsModifyingOrder] = useState(false);
  const [orderToModify, setOrderToModify] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(initialSymbol);

  // Update subscribedConids when selectedSymbol changes
  useEffect(() => {
    const conidsToSubscribe = new Set();
    
    if (selectedSymbol?.conid) {
      conidsToSubscribe.add(selectedSymbol.conid.toString());
    }
    
    setSubscribedConids(Array.from(conidsToSubscribe));
  }, [selectedSymbol]);

  // Subscribe to active trades conids on initial load
  useEffect(() => {
    const subscribeActiveTrades = () => {
      if (activeTrades.length > 0) {
        setSubscribedConids(prevConids => {
          const newConids = new Set(prevConids);
          activeTrades.forEach(trade => {
            if (trade.conid) {
              newConids.add(trade.conid.toString());
            }
          });
          return Array.from(newConids);
        });
      }
    };

    // Run once on initial load
    subscribeActiveTrades();
  }, []); // Empty dependency array ensures it runs only once

  // Handle market data updates
  const handleMarketDataUpdate = useCallback((message) => {
    if (message.market_data) {
      const entries = Object.entries(message.market_data);
      if (entries.length > 0) {
        const [conid, data] = entries[0];
        
        setMarketData(prevData => {
          const existingData = prevData[conid] || {};
          return {
            ...prevData,
            [conid]: {
              conid: parseInt(conid),
              bid_price: data.bid_price || existingData.bid_price,
              volume: data.volume || existingData.volume,
              change_percent: data.change_percent || existingData.change_percent,
              _updated: Date.now()
            }
          };
        });
      }
    }
  }, []);

  // Connect to WebSocket when authenticated
  useEffect(() => {
    if (isAuthenticated && subscribedConids.length > 0) {
      const setupWebSocket = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: { audience: process.env.REACT_APP_BACKEND_URL },
          });
          
          // Set up message handler first
          webSocketManager.onMessage(handleMarketDataUpdate);
          
          // Connect with all conids at once
          webSocketManager.connect(accessToken, subscribedConids);
          console.log('Subscribed to market data for conids:', subscribedConids);
        } catch (error) {
          console.error('Failed to setup WebSocket:', error);
        }
      };

      setupWebSocket();

      return () => {
        webSocketManager.disconnect();
      };
    }
  }, [isAuthenticated, subscribedConids, handleMarketDataUpdate, getAccessTokenSilently]);

  const startOrderModification = useCallback((order) => {
    setIsModifyingOrder(true);
    console.log('startOrderModification');
    console.log(order);
    setOrderToModify(order);
    setSelectedSymbol({
      label: order.data.symbol,
      conid: order.data.conid,
      // name: order.order_status[0].company_name,
      // exchange: order.order_status[0].exchange,
      // currency: order.order_status[0].currency
    });
  }, []);

  const cancelOrderModification = useCallback(() => {
    setIsModifyingOrder(false);
    setOrderToModify(null);
  }, []);

  return (
    <WebTraderContext.Provider 
      value={{
        // Market data
        marketData,
        subscribedConids,
        
        // Symbol selection
        selectedSymbol,
        setSelectedSymbol,
        
        // Order modification
        isModifyingOrder,
        orderToModify,
        startOrderModification,
        cancelOrderModification
      }}
    >
      {children}
    </WebTraderContext.Provider>
  );
}

export function useWebTrader() {
  const context = useContext(WebTraderContext);
  if (!context) {
    throw new Error('useWebTrader must be used within a WebTraderProvider');
  }
  return context;
}