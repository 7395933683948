import { createAsyncThunk, createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";

// Thunks
export const fetchOrders = createAsyncThunk(
  'order/fetchOrders', 
  async ({ strategyId, subscriptionId, refresh = false }) => {
    const url = strategyId ? `/order/strategy/${strategyId}/` : `/subscription/${subscriptionId}/orders`;
    const params = refresh ? { refresh: true } : {};
    
    return authAxios.get(url, { params }).then(response => response.data);
  }
);

export const createOrder = createAsyncThunk('order/createOrder', ({strategyId, orderData}) => {
  return authAxios.post(`/order/strategy/${strategyId}/`, {'data': orderData}).then(response => response.data);
});

export const updateOrder = createAsyncThunk(
  'order/updateOrder', 
  async ({ strategyId, orderId, orderData }) => {
    const response = await authAxios.put(
      `/order/strategy/${strategyId}/${orderId}/`, 
      {'data': orderData}
    );
    
    return {
      ...response.data,
      id: orderId,
      _responseTime: new Date().toISOString()
    };
  }
);

export const deleteOrder = createAsyncThunk(
  'order/deleteOrder', 
  async ({ strategyId, orderId }) => {
    return authAxios
      .delete(`/order/strategy/${strategyId}/${orderId}/`)
      .then(response => ({
        id: orderId,
        ...response.data
      }));
  }
);

export const ordersAdapter = createEntityAdapter();

// Slice
export const orderSlice = createSlice({
  name: 'order',
  initialState: ordersAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        ordersAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const existingOrders = ordersAdapter.getSelectors().selectAll(state);
        const newOrders = Array.isArray(action.payload) ? action.payload : [action.payload];
        ordersAdapter.removeAll(state);
        ordersAdapter.addMany(state, [...newOrders, ...existingOrders]);
        
        state.loading = false;
        state.error = null;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        ordersAdapter.upsertOne(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        ordersAdapter.upsertOne(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  selectById: selectOrderById,
  selectAll: selectAllOrders,
} = ordersAdapter.getSelectors((state) => state.order);


export default orderSlice.reducer;