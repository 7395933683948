import React, {useEffect, useState} from "react";
import _ from "lodash"
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {enqueueSnackbar} from 'notistack';
import {useSelector, useDispatch} from 'react-redux';
import {styled, useTheme} from '@mui/material/styles';
import {routes} from "routes";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {Box} from "@mui/system";
import { PageHeader } from "components/molecules/PageHeader/PageHeader";
import {Paper as CustomPaper} from "components/atoms/Paper/Paper";
import {Card, Link, MenuList, Switch, ToggleButton} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from "@mui/material/Stack";
import { Table, TableBody, TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material';
import TableContainer from "@mui/material/TableContainer";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Tabs, {tabsClasses} from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "components/atoms/TabPanel/TabPanel";
import { DataGrid } from '@mui/x-data-grid';
import CallMissedOutgoingOutlinedIcon from '@mui/icons-material/CallMissedOutgoingOutlined';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NestedMenuItem } from 'mui-nested-menu';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import useMediaQuery from "@mui/material/useMediaQuery";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {addToWatchList, selectStrategyById, togglePublicState} from "redux/strategy/strategySlice";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {createSubscription, selectSubscriptionByStrategyId} from "redux/subscription/subscriptionSlice";
import StyledCardComponent from "components/molecules/StyledCardElement/StyledCardComponent";
import LinearProgress from '@mui/material/LinearProgress';
import {selectLinkedAccountByBrokerID} from "redux/linkedAccount/linkedAccountSlice";
import {deleteSimulation, selectSimulationByStrategyId} from "redux/simulation/simulationSlice";
import {useConfirmation} from "utils/ConfirmationProvider";




const LargeWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});


export default function StrategyDetails() {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirmation()
  const isAuthorised = useSelector(state => state.global.isAuthorised);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const strategyId = params.strategyId;
  const strategy = useSelector((state) => selectStrategyById(state, strategyId)) ;
  const subscription = useSelector((state) => selectSubscriptionByStrategyId( state, strategyId));
  const simulation = useSelector((state) => selectSimulationByStrategyId( state, strategyId));
  const linked_account = useSelector((state) => selectLinkedAccountByBrokerID(state, strategy.broker.type));
  const stripe = useStripe();
  const elements = useElements();
  const [subscribeSubmitting, setSubscribeSubmitting] = useState(false);
  const [subscribeError, setSubscribeError] = useState(null);

  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);

  const handleOpenSubscriptionDialog = () => {

      console.log(simulation);

      if (_.isEmpty(linked_account)) {
        confirm({
          title: `Connect a Broker Account`,
          message: <>In order to subscribe to <b>{strategy.name}</b> you need to connect to your <b>{strategy.broker.name}</b> account.</>,
          onConfirm: async () => {
            navigate(`${routes.brokerAccountConnect}?broker=${strategy.broker.type}`)
          },
          maxWidth: "md"
        });
      } else{

        if(linked_account[0].status === 'pending') {
          confirm({
          title: `Broker Account is awaiting approval`,
          message: <>You <b>{strategy.broker.name}</b> account is awaiting approval. <br></br> Please wait for the account to be activated. </>,
          onConfirm: async () => {

          },
          maxWidth: "md"
        });

        } else {
          setSubscribeError(false)
          setOpenSubscriptionDialog(true);
        }
      }
  }

  const handleCloseSubscriptionDialog = () => {
      setOpenSubscriptionDialog(false);
  };

  const handleSubmitSubscription = async (event) => {
        setSubscribeSubmitting(true)
        setSubscribeError(false)
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            setSubscribeError(result.error.message)
            setSubscribeSubmitting(false)
            return;
        }

        const token = result.token;

        const subscriptionData = {
           token: token.id,
           strategy: strategy.id,
           linked_account: linked_account[0].id
        };

        if(!_.isEmpty(simulation)) {
          await dispatch(deleteSimulation(simulation[0].id));
        }
        await dispatch(createSubscription(subscriptionData)).unwrap();
        setSubscribeSubmitting(false)
        setOpenSubscriptionDialog(false)
        enqueueSnackbar('You have subscribed successfully.', { variant: 'success' });
    };

  useEffect(() => {

  }, [strategy]);

  const [value, setValue] = React.useState(0);
  const [selected, setSelected] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openIndex, setOpenIndex] = React.useState(null);


  // const open = (index) => Boolean(anchorEl[index]);
  const handleClick = (event) => {
    setOpenIndex(parseInt(event.currentTarget.getAttribute('data-index')))
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenIndex(null);
  };


  const togglePublic = async () => {
      await dispatch(togglePublicState(strategyId)).unwrap();
      enqueueSnackbar(`Strategy has been set to "${strategy.public? 'Private': 'Public'}".`, { variant: 'success' });
  }



  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const years = ['2021', '2022', '2023'];

  const trade_columns = [
    { field: 'opened', headerName: 'Opened Date/Time',  minWidth: 200, },
    { field: 'symbol', headerName: 'Symbol',  },
    { field: 'description', headerName: 'Description', flex: 0.1 },
    { field: 'side', headerName: 'Side',  },
    { field: 'qty', headerName: 'Qty',  },
    { field: 'avgprice1', headerName: 'Avg Price',   },
    { field: 'closed', headerName: 'Closed Date/Time',   minWidth: 200, },
    { field: 'avgprice2', headerName: 'Avg Price',  },
    { field: 'drawdown', headerName: 'Drawdown',  },
    { field: 'pl', headerName: 'P/L', width: 50 },
  ]
  const trade_rows = [
  { id: 1, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14578", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
  { id: 2, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14579", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
  { id: 3, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14578", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
  { id: 4, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14578", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
  { id: 5, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14578", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
  { id: 6, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14578", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
  { id: 7, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14578", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
  { id: 8, opened: "1/6/23 0:52", symbol: "DXSH3", description: "MICRO-DAX INDEX", side: "SHORT", qty: 1, avgprice1: "14578", closed :"1/8/23 0:55", avgprice2: "14578", drawdown: "0%", pl: "$(2)"},
];

  const chartOptions = {
    chart: {
      zoomType: 'x',
      backgroundColor: '#F2F2F2'
    },
    title: {
      text: 'EUROPEAN STOCK BASKET',
      align: 'left'
    },
    subtitle: {
      text: document.ontouchstart === undefined ?
        'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
      align: 'left'
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      title: {
        text: 'Exchange rate'
      }
    },
    colors: ["#16171a"],
    legend: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 2
          },
          stops: [
            [0, "#E6E6E6"],

          ]
        },
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },

    series: [{
      type: 'area',
      name: 'USD to EUR',
      data: [
        [Date.UTC(2022, 0, 1), 1.1256],
        [Date.UTC(2022, 0, 2), 1.1243],
        [Date.UTC(2022, 0, 3), 1.1279],
        [Date.UTC(2022, 0, 4), 1.1208],
        [Date.UTC(2022, 0, 5), 1.1186],
        [Date.UTC(2022, 0, 6), 1.1234],
        [Date.UTC(2022, 0, 7), 1.1192],
        [Date.UTC(2022, 0, 8), 1.1175],
        [Date.UTC(2022, 0, 9), 1.1217],
        [Date.UTC(2022, 0, 10), 1.1265],
        [Date.UTC(2022, 0, 11), 1.1292],
        [Date.UTC(2022, 0, 12), 1.1318],
        [Date.UTC(2022, 0, 13), 1.1357],
        [Date.UTC(2022, 0, 14), 1.1331],
        [Date.UTC(2022, 0, 15), 1.1368],
        [Date.UTC(2022, 0, 16), 1.1379],
        [Date.UTC(2022, 0, 17), 1.1412],
        [Date.UTC(2022, 0, 18), 1.1435],
        [Date.UTC(2022, 0, 19), 1.1443],
        [Date.UTC(2022, 0, 20), 1.1467],
        [Date.UTC(2022, 0, 21), 1.1481],
        [Date.UTC(2022, 0, 22), 1.1526],
        [Date.UTC(2022, 0, 23), 1.1562],
        [Date.UTC(2022, 0, 24), 1.1553],
        [Date.UTC(2022, 0, 25), 1.1549],
        [Date.UTC(2022, 0, 26), 1.1521],
        [Date.UTC(2022, 0, 27), 1.1502],
        [Date.UTC(2022, 0, 28), 1.1479],
        [Date.UTC(2022, 0, 29), 1.1458],
        [Date.UTC(2022, 0, 30), 1.1427],
        [Date.UTC(2022, 0, 31), 1.1394]
      ]
    }]


  };

  const { uid } = useParams();

  return (
    <>
      {strategy && <>

        {strategy.owned && <>
               <Menu anchorEl={anchorEl} open={openIndex === 0 } onClose={handleClose}  anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }}   >
                <MenuList sx={{ pt:0, pb: 0}}>
                  <MenuItem onClick={handleClose} component={RouterLink} to={routes.strategyWebTrader} >Enter new trade</MenuItem>
                  <MenuItem onClick={handleClose}>Edit your strategy</MenuItem>
                  <NestedMenuItem sx={{pl: 1}}
                    label="Market your strategy"
                    parentMenuOpen={true}
                  >
                      <MenuItem onClick={handleClose}>Invite to strategy</MenuItem>
                      <MenuItem onClick={handleClose}>Feature this strategy </MenuItem>
                  </NestedMenuItem>
                  <MenuItem onClick={handleClose}>Extend listing period</MenuItem>
                  <MenuItem onClick={handleClose}>Rescale</MenuItem>
                  <MenuItem onClick={handleClose}>Position size limit</MenuItem>
                </MenuList>
              </Menu>
                <Menu anchorEl={anchorEl} open={openIndex === 1} onClose={handleClose}   anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} >
                  <MenuList sx={{ pt:0, pb: 0}}>
                    <MenuItem onClick={handleClose}>List subscribers</MenuItem>
                    <MenuItem onClick={handleClose}>Broadcast message</MenuItem>
                  </MenuList>
                </Menu>


              <Box  sx={{ mb: 5, display: {xs: 'none', sm: 'block'}}} >
          <ButtonGroup orientation="horizontal" variant="outlined" >
            <Button variant={"outlined"} onClick={() => navigate(routes.strategyWebTrader.replace(":strategyId", strategy.id))} startIcon={<CallMissedOutgoingOutlinedIcon />} >Enter Trade</Button>
            <Button variant={"outlined"} startIcon={<VisibilityOffOutlinedIcon/>} >Unsubscribe</Button>
            <Button variant={"outlined"} startIcon={<AutorenewOutlinedIcon/>} >Auto Trade</Button>
            <Button onClick={handleClick} data-index={0} variant="outlined" startIcon={<SettingsOutlinedIcon/>}endIcon={<KeyboardArrowDownIcon/>} size={mobile? "large": "small"}> Manage </Button>
            <Button onClick={handleClick} data-index={1} variant="outlined" startIcon={<GroupAddOutlinedIcon/>} endIcon={<KeyboardArrowDownIcon/>} size={mobile? "large": "small"}> Subscribers </Button>
          </ButtonGroup>

        </Box>


          </>}

    <small>(137202953)</small>
      <Box sx={{ mb: 5, display: { xs: "block", sm: "flex"}}} justifyContent="space-between" alignItems="center">
        <PageHeader text1={strategy.name} text2={"strategy"} description={"These are hypothetical performance results that have certain inherent limitations."}/>
        {strategy.owned ?
         <Card sx={{p: 1, pl: 2, pr: 2, mt: { xs: 5, sm: 0}}}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Private</Typography>
            <Switch color="secondary" checked={strategy.public} onClick={() => togglePublic()}/>
            <Typography>Public</Typography>
          </Stack>
        </Card> :
          <>
          {_.isEmpty(subscription) &&
            <>

        <Button variant="contained" color="secondary" sx={{ mt: 3}} onClick={handleOpenSubscriptionDialog} disabled={!isAuthorised}>
          <Typography sx={{ textTransform: 'none' }}>
           <b>Subscribe</b> <br />
           <small>Full access for ${strategy.charge}/mo</small>
          </Typography>
        </Button>



           <Dialog

             fullWidth={true}
               open={openSubscriptionDialog}
               onClose={handleCloseSubscriptionDialog}
           >
               <DialogTitle >
                 Subscribe

               </DialogTitle>
               <DialogContent>


                  <StyledCardComponent onCardChange={() => setSubscribeError(null)} />
                 {setSubscribeError && <div style={{ color: 'red' }}>{subscribeError}</div>}

                  <Grid container>
                    <Grid item sm={6}></Grid>
                    <Grid item sm={6} sx={{mt: 3}}>
                                                     <Table size="small" align="right"  sx={{
    [`& .${tableCellClasses.root}`]: {
      borderBottom: "none"
    }
  }}>
                    <TableBody>
                      <TableRow align="right">
                        <TableCell align="right">Strategy Name</TableCell>
                        <TableCell align="right"><Typography fontWeight={500} variant="span">{strategy.name}</Typography></TableCell>
                      </TableRow>
                        <TableRow>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right"><Typography fontWeight={500} variant="span">{strategy.charge} $</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">Recurrence</TableCell>
                            <TableCell align="right"><Typography fontWeight={500} variant="span">Monthly</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                    </Grid>

                  </Grid>



               </DialogContent>
               <DialogActions sx={{p: 3}}>
                   <Button onClick={handleCloseSubscriptionDialog} color="primary">
                       Close
                   </Button>

                 <Button onClick={handleSubmitSubscription} color="secondary" variant="contained" disabled={subscribeSubmitting}>
                    Subscribe
                </Button>
               </DialogActions>
             {subscribeSubmitting && <Box sx={{ width: '100%' }}>
      <LinearProgress color="secondary"/>
    </Box>}

           </Dialog> </>
          }

          </>


        }

      </Box>

       <Box  sx={{ mb: 5, display: {xs: 'block', sm: 'none'}}} >

            <ButtonGroup orientation="vertical" variant="outlined" fullWidth>
            <Button variant={"outlined"} startIcon={<CallMissedOutgoingOutlinedIcon />} size="large">Enter Trade</Button>
            <Button variant={"outlined"} startIcon={<VisibilityOffOutlinedIcon/>} size="large">Unsubscribe</Button>
            <Button variant={"outlined"} startIcon={<AutorenewOutlinedIcon/>} size="large">Auto Trade</Button>
            <Button onClick={handleClick} data-index={0} variant="outlined" size="large" startIcon={<SettingsOutlinedIcon/>} endIcon={<KeyboardArrowDownIcon/>} > Manage </Button>
            <Button onClick={handleClick} data-index={1} variant="outlined" size="large" startIcon={<GroupAddOutlinedIcon/>} endIcon={<KeyboardArrowDownIcon/>} > Subscribers </Button>
          </ButtonGroup>
       </Box>

    {strategy.owned === false &&
      <Box sx={{ flexGrow: 1, mb: 5 }}>
      <Grid container spacing={2}>
          <Grid xs={6} sm={4} md={2}>
            <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" align="center" >57.2%</Typography>
             <LargeWidthTooltip
              title={
               <>
                 <p>Rate of Return Calculations</p>
                 <p>Overview</p>
                 <p>To comply with NFA regulations, we display Cumulative Rate of Return for strategies with a track record of less than one year. For strategies with longer track records, we display Annualized (Compounded) Rate of Return. </p>
                 <p>How Annualized (Compounded) Rate of Return is calculated</p>
                 <p>= ((Ending_equity / Starting_equity) ^ (1 / age_in_years)) - 1</p>
                 <p>Remember that, following NFA requirements, strategy subscription costs and estimated commissions are included in marked-to-market equity calculations.</p>
                 <p>All results are hypothetical.</p>
               </>
               }>
              <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Annual Return (Compounded)&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
             </LargeWidthTooltip>
            </Card>
          </Grid>
        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" color="error.main" align="center"   >(46.6%)</Typography>
            <LargeWidthTooltip
              title={
                <>
                  <p>The largest peak-to-valley historical drawdown of the Model Account was 46.6% and occurred between 2021-09-13 and 2022-10-02. In terms of dollars, the largest peak-to-valley drawdown of the Model Account was $41,562. Future drawdowns may be greater than this number.</p>
                  <p>This number assumes typical broker commissions and fees are included in strategy performance.</p>
                </>
              }>
              <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Max Drawdown&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
            </LargeWidthTooltip>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" color="light.main" align="center" >372</Typography>
            <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Num Trades</Typography>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" color="light.main" align="center" >70.7%</Typography>
            <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Win Trades</Typography>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" align="center" >1.4 <small>:1</small></Typography>
            <Tooltip title="The Profit Factor (also called the Win-to-Loss Ratio) is calculated by dividing total trade profits by total trade losses.">
              <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Protif Factor&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
            </Tooltip>
          </Card>
        </Grid>

        <Grid xs={6} sm={4} md={2}>
          <Card sx={{p: 3}}>
            <Typography component="div" variant="largeText" align="center" >62.5%</Typography>
            <Typography variant="smallText" alignItems="center" justifyContent="center" component={Stack} direction="row">Win Months</Typography>
          </Card>
        </Grid>

        </Grid>
      </Box>
    }

      <Box >
        <Grid container spacing={2}>

          <Grid md={8} xs={12} order={{ xs: 2, md: 1 }}>

                           <Typography variant="h6" sx={{ mt: 3 }} >Performance </Typography>
               <Typography color={"secondary.dark"} fontWeight={500}>Hypothetical</Typography>
              <Box sx={{ mt: 3}}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />

              </Box>


              <Typography variant="h6" >Monthly Returns </Typography>
              <Typography color={"secondary.dark"} fontWeight={500}>Hypothetical</Typography>
              <TableContainer >
                <Table size="small">
                  <caption>Includes system fee and Interactive Brokers commissions and fees</caption>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {months.map((month) => (
                        <TableCell key={month} align="center">
                          {month}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <Typography fontWeight="bold">YTD</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {years.map((year) => (
                      <TableRow hover key={year}>
                        <TableCell>
                          <Typography variant="subtitle1">{year}</Typography>
                        </TableCell>
                        {months.map((month) => (
                          <TableCell key={`${year}-${month}`} align="center">
                              {`${Math.floor(Math.random() * 20)}%`}
                          </TableCell>
                        ))}
                        <TableCell align="center">
                            {`${Math.floor(Math.random() * 100)}%`}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>




          </Grid>
          <Grid md={4} xs={12} order={{ xs: 1, md: 2 }} sx={{ mb: 3}}>
            <CustomPaper variant="light2" sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 3}}>Summary Statistics</Typography>

              <Table size="small">
                <TableRow hover>
                  <TableCell variant="head">Strategy began</TableCell>
                  <TableCell align="right">2020-10-29</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head">
                    <Tooltip title="This is our estimate of the minimum amount of capital to follow a strategy, assuming you use the smallest reasonable AutoTrade Scaling % for the strategy.">
                    <Typography variant="" alignItems="center" component={Stack} direction="row"> Suggested Minimum Capital&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">$80,000</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head"># Trades</TableCell>
                  <TableCell align="right">375</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head"># Profitable</TableCell>
                  <TableCell align="right">268</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head">% Profitable</TableCell>
                  <TableCell align="right">71.5%</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head">Correlation S&P500</TableCell>
                  <TableCell align="right">0.259</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head">Sharpe Ratio</TableCell>
                  <TableCell align="right">1.20</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head">Sortino Ratio</TableCell>
                  <TableCell align="right">2.02</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head">Beta</TableCell>
                  <TableCell align="right">0.50</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell variant="head">Alpha</TableCell>
                  <TableCell align="right">0.13</TableCell>
                </TableRow>
                <TableRow hover sx={{ "& td": { border: 0 } }}>
                  <TableCell variant="head">Leverage</TableCell>
                  <TableCell align="right" >
                    Average:&nbsp;0.13 <br/>
                    Maximum:&nbsp;0.13
                  </TableCell>
                </TableRow>
              </Table>

            </CustomPaper>
          </Grid>
        </Grid>

      <Grid container>
        <Grid xs={12} sm={12} sx={{ mt: 5}}>

<Tabs
        value={value}
        onChange={handleChange}
         variant="scrollable"
          allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
         sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab label="Trade Record" />
        <Tab label="Statistics" />
        <Tab label="Description" />
        <Tab label="Margin Use" />
      </Tabs>

        <Box sx={{mt: 2}}>
        <TabPanel name="trade-record" value={value} index={0}>

                <DataGrid
        rows={trade_rows}
        columns={trade_columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />



        </TabPanel>
        <TabPanel name="statistics" value={value} index={1}>
          <Grid sx={12} sm={8}>
          <Table size="small">
            <TableRow >
              <TableCell  variant="head" cellSpan={2}><Typography variant="h5">Statistics </Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell >Strategy began</TableCell>
              <TableCell align="left">2020-10-29</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >
                <Tooltip title="This is our estimate of the minimum amount of capital to follow a strategy, assuming you use the smallest reasonable AutoTrade Scaling % for the strategy.">
                <Typography variant="" alignItems="center" component={Stack} direction="row"> Suggested Minimum Capital&nbsp;<InfoOutlinedIcon fontSize="string"/></Typography>
                </Tooltip>
              </TableCell>
              <TableCell align="left">$80,000</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell ># Trades</TableCell>
              <TableCell align="left">375</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell ># Profitable</TableCell>
              <TableCell align="left">268</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >% Profitable</TableCell>
              <TableCell align="left">71.5%</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Correlation S&P500</TableCell>
              <TableCell align="left">0.259</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Sharpe Ratio</TableCell>
              <TableCell align="left">1.20</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Sortino Ratio</TableCell>
              <TableCell align="left">2.02</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Beta</TableCell>
              <TableCell align="left">0.50</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Alpha</TableCell>
              <TableCell align="left">0.13</TableCell>
            </TableRow>
         <TableRow >
              <TableCell  variant="head" cellSpan={2}><Typography variant="h5">Model Account Values (Raw) </Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>
                        <TableRow hover>
              <TableCell >Sortino Ratio</TableCell>
              <TableCell align="left">2.02</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Beta</TableCell>
              <TableCell align="left">0.50</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Alpha</TableCell>
              <TableCell align="left">0.13</TableCell>
            </TableRow>
        <TableRow >
              <TableCell  variant="head" cellSpan={2}><Typography variant="h5">Ratios </Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>
                        <TableRow hover>
              <TableCell >Sortino Ratio</TableCell>
              <TableCell align="left">2.02</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Beta</TableCell>
              <TableCell align="left">0.50</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell >Alpha</TableCell>
              <TableCell align="left">0.13</TableCell>
            </TableRow>

          </Table>
            </Grid>

        </TabPanel>
        <TabPanel name="account" value={value} index={2}>
          Donec libero nunc, venenatis sed auctor vel, varius et nisl. Donec dapibus eros eu molestie consectetur. Quisque vel justo ac est molestie vehicula. Quisque quis libero quis nisi varius interdum. Vestibulum vehicula porta placerat. Cras eleifend dui at dui ultrices, scelerisque sodales mi commodo. Duis eu neque ut risus congue porta eget et sem. Duis vitae velit suscipit, porttitor neque elementum, viverra quam. Nulla commodo sed lectus vel euismod. Fusce sit amet sagittis urna. Phasellus maximus justo vitae euismod lobortis. Mauris lacinia libero a dui varius condimentum. Duis gravida pulvinar tempus. Nunc dictum enim eget lorem bibendum, eu lobortis turpis consequat.
        </TabPanel>

        <TabPanel name="margin-use" value={value} index={3}>
          No open positions.
        </TabPanel>

        </Box>


        </Grid>

      </Grid>

      </Box>

       <Box sx={{mt: 5}}>
        <CustomPaper variant="light2" >
          <Typography variant="h5">About the results you see on this Web site</Typography>
          <Typography component="p" sx={{mt:2}}>Past results are not necessarily indicative of future results.</Typography>
          <Typography component="p" sx={{mt:2}}>These results are based on simulated or hypothetical performance results. Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.</Typography>
          <Typography component="p" sx={{mt:2}}>One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results.</Typography>
          <Typography component="p" sx={{mt:2}}>You may be interested to <Link>learn more technical details</Link> about how The Portfolio Platform calculates the hypothetical results you see on this web site.</Typography>
          <Typography variant="h5" sx={{mt:2}}>Material assumptions and methods used when calculating results</Typography>
          <Typography component="p" sx={{mt:2}}>The following are material assumptions used when calculating any hypothetical monthly results that appear on our web site. </Typography>
          <ul>
            <li><strong>Profits are reinvested.</strong> We assume profits (when there are profits) are reinvested in the trading strategy.</li>
            <li><strong>Starting investment size.</strong> For any trading strategy on our site, hypothetical results are based on the assumption that you invested the starting amount shown on the strategy's performance chart. In some cases, nominal dollar amounts on the equity chart have been re-scaled downward to make current go-forward trading sizes more manageable. In these cases, it may not have been possible to trade the strategy historically at the equity levels shown on the chart, and a higher minimum capital was required in the past.</li>
            <li><strong>All fees are included.</strong> When calculating cumulative returns, we try to estimate and include all the fees a typical trader incurs when AutoTrading using AutoTrade technology. This includes the subscription cost of the strategy, plus any per-trade AutoTrade fees, plus estimated broker commissions if any.</li>
            <li><strong>"Max Drawdown" Calculation Method.</strong> We calculate the Max Drawdown statistic as follows. Our computer software looks at the equity chart of the system in question and finds the largest percentage amount that the equity chart ever declines from a local "peak" to a subsequent point in time (thus this is formally called "Maximum Peak to Valley Drawdown.") While this is useful information when evaluating trading systems, you should keep in mind that past performance does not guarantee future results. Therefore, future drawdowns may be larger than the historical maximum drawdowns you see here.</li>
          </ul>
          <Typography variant="h5" sx={{mt:2}}>Trading is risky </Typography>
          <Typography component="p" sx={{mt:2}}>There is a substantial risk of loss in futures and forex trading. Online trading of stocks and options is extremely risky. Assume you will lose money. Don't trade with money you cannot afford to lose.</Typography>
        </CustomPaper>
      </Box>


        </>}
    </>
  )
}
