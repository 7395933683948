import React, {useEffect} from 'react';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import { useSnackbar } from 'notistack'
import {useNavigate, useParams} from 'react-router-dom';
import {createStrategy, selectStrategyById, updateStrategy} from 'redux/strategy/strategySlice';
import {Field, Form, Formik, FieldArray} from 'formik';
import {Button, Card, FormControl, Grid, MenuItem} from '@mui/material';
import {Select as SelectInput, TextField, CheckboxWithLabel} from 'formik-mui';
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import Typography from "@mui/material/Typography";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from "@mui/system";
import {selectAllLinkedAccounts} from "redux/linkedAccount/linkedAccountSlice";
import {routes} from "routes";
import Divider from "@mui/material/Divider";
import { FormHelperText } from '@mui/material';

const StrategyForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams();
  const navigate = useNavigate();
  const strategyId = params.strategyId;
  const linkedAccounts = useSelector(selectAllLinkedAccounts);
  const strategy = useSelector((state) => selectStrategyById(state, strategyId)) ;

  useEffect(() => {

  }, []);

  const handleSubmit = async (values) => {
        enqueueSnackbar('Strategy has been updated successfully.', {variant: 'success'});

    try {
      if(strategy){
        await dispatch(updateStrategy({strategyId: strategy.id, strategyData: values})).unwrap();
        enqueueSnackbar('Strategy has been updated successfully.', {variant: 'success'});
        navigate(routes.strategyDetails.replace(":strategyId", strategy.id));
      } else {
        const response = await dispatch(createStrategy(values)).unwrap();
        enqueueSnackbar('Strategy has been created successfully.', {variant: 'success'})
        navigate(routes.strategyDetails.replace(":strategyId", response.id));
      }

    } catch (error) {
      enqueueSnackbar(error, {variant: 'error'})
    }


  }

  const initialValues = strategy || {
    name: '',
    linked_account: '',
    min_capital: '',
    trade_type: '',
    charge: '',
    brief_summary: '',
    description: '',
  };

  const StrategySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    linked_account: Yup.string().required('Broker Account is required'),
    trade_type: Yup.array().min(1, 'Trade Type is required').required('Trade Type is required'),
    min_capital: Yup.string().required('Minimum Capital is required'),
    charge: Yup.string().required('Field is required'),
  });

  return (
    <>

    {strategy ?
      <PageHeader text1={"Edit"} text2={"Strategy"} description={strategy.name}/> :
      <PageHeader text1={"Create"} text2={"Strategy"} description={"Create new strategy"}/>
    }

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Card sx={{mt: 3, p: 3}}>
            <Formik initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={StrategySchema}
            >
              {({isSubmitting, errors, touched}) => (
                <Form>
                  <Typography variant="mediumText">1. Name your strategy</Typography>
                  <Box sx={{ml: 2, mr: 2}}>
                    <Field component={TextField} id="name" name="name" label="Name" fullWidth margin="normal" size="normal" helperText='The strategy name is your "brand." Make it short and memorable.' />
                  </Box>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Typography variant="mediumText">2. Broker account</Typography>
                  <Box sx={{ml: 2, mr: 2}}>
                  <FormControl fullWidth margin="normal" size="normal">
                    <Field component={SelectInput} id="linked_account" name="linked_account" label="Broker Account" >
                      {linkedAccounts.map((account, index) => (
                        <MenuItem key={account.id} value={account.id}>{account.broker_name} &nbsp; (U10455755)</MenuItem>
                      ))}
                    </Field>
                    <FormHelperText >Broker account associated with the strategy.</FormHelperText>
                  </FormControl>
                  </Box>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Typography variant="mediumText">3. What will you trade?</Typography>
                  <Box sx={{ml: 2, mr: 2}}>
                    <FormControl fullWidth margin="normal" size="normal" >

                    <FieldArray
                      name="trade_type"
                      render={arrayHelpers => (
                        <Box display="flex" gap={2}>
                          {['Stocks', 'Futures', 'Forex', 'Options'].map((type, index) => (
                            <Field
                              key={index}
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="trade_type"
                              value={type.toLowerCase()}
                              Label={{ label: type }}
                              checked={arrayHelpers.form.values.trade_type.includes(type.toLowerCase())}
                              onChange={e => {
                                if (e.target.checked) arrayHelpers.push(type.toLowerCase());
                                else {
                                  const idx = arrayHelpers.form.values.trade_type.indexOf(type.toLowerCase());
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          ))}
                        </Box>
                      )}
                    />
                     {touched.trade_type && errors.trade_type ? (
                        <FormHelperText error>{errors.trade_type}</FormHelperText>
                     ) : null}
                    </FormControl>
                  </Box>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Typography variant="mediumText">4. Model Account Size</Typography>
                  <Box sx={{ml: 2, mr: 2}}>
                   <FormControl fullWidth margin="normal" size="normal" >
                    <Field component={SelectInput} id="min_capital" name="min_capital" label="Minimal Capital">
                      <MenuItem value="10000">$10,000</MenuItem>
                      <MenuItem value="25000">$25,000</MenuItem>
                      <MenuItem value="50000">$50,000</MenuItem>
                      <MenuItem value="100000">$100,000</MenuItem>
                      <MenuItem value="250000">$250,000</MenuItem>
                    </Field>
                  </FormControl>
                 </Box>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Typography variant="mediumText">5. How much to charge?</Typography>
                  <Box sx={{ml: 2, mr: 2}}>
                    <Field component={TextField} id="charge" name="charge" label="Price per month (USD)" type="number" fullWidth margin="normal" size="normal"   />
                    <FormHelperText >The systems that earn the most money on The Portfolio Platform charge between $100 and $250 per month..</FormHelperText>
                  </Box>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Typography variant="mediumText">6. Strategy summary</Typography>
                  <Box sx={{ml: 2, mr: 2}}>
                    <Field component={TextField} id="brief_summary" name="brief_summary" label="Brief Summary" multiline rows={4} fullWidth margin="normal" size="normal" />
                    <Field component={TextField} id="description" name="description" label="Description" multiline rows={4} fullWidth margin="normal" size="normal" />
                  </Box>

                  <Box sx={{ml: 2, mr: 2}}>
                  <Typography align="right">
                    {strategy ?
                      <Button sx={{mt: 2}} type="submit" variant="contained" color="secondary" endIcon={<EditIcon/>} disabled={isSubmitting}> Edit Strategy </Button> :
                      <Button sx={{mt: 2}} type="submit" variant="contained" color="secondary" endIcon={<AddOutlinedIcon/>} disabled={isSubmitting}> Create Strategy </Button> }
                  </Typography>
                  </Box>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{mt: 3}}>
            <Typography variant="h5">How to create Strategy?</Typography>
            <Typography component="p" sx={{mt: 2}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit
              amet dolor ac ligula condimentum ornare id vel lacus. Crcomponent a consectetur est. Morbi consectetur
              lacus
              neque, vitae egestas tellus feugiat sagittis. Suspendisse vel pulvinar nulla, at iaculis arcu. Vivamus
              fringilla dictum ligula vitae aliquam. Nam malesuada eros eget sagittis eleifend. Aliquam id porttitor
              eros. Nullam quis urna a magna imperdiet auctor a eu dui. Maecenas dictum pellentesque tortor, eget
              sollicitudin erat finibus varius. Nam suscipit sagittis justo ac rhoncus. Pellentesque accumsan, est vel
              fermentum scelerisque, tortor dui fermentum ligula, in hendrerit mauris leo non nulla. Interdum et
              malesuada fames ac ante ipsum primis in faucibus. Pellentesque mollis semper sodales. Cras eget tincidunt
              velit. Duis laoreet, sem eget tristique tempor, elit leo porta purus, vel condimentum magna velit eu mi.
              Pellentesque sit amet sem cursus, scelerisque arcu at, vehicula justo.</Typography>
          </Box>
        </Grid>

      </Grid>
    </>
  )


};

export default StrategyForm;
