import React, {useState} from 'react';
import _ from "lodash"
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { TextField, Select as SelectInput } from 'formik-mui';
import {FormControl, Grid, Link, MenuItem} from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Logo from "images/TPP_Logo_Rev.svg";
import CircularProgress from "@mui/material/CircularProgress";
import UploadIcon from "@mui/icons-material/Upload";
import {submitAnonymousKYC} from "redux/kyc/kycSlice";
import Collapse from "@mui/material/Collapse";
import ReCAPTCHA from "react-google-recaptcha";



const ProfileBoxWrapper = styled(Box)(
  ({theme}) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: '100vh',
      backgroundColor: '#000',
    }
  ),
)

const ProfileBox = styled(Box)(
  ({theme}) => ({
    maxWidth: '650px',
    padding: '50px',
    backgroundColor: theme.main.bgDark,
    color: theme.palette.light.main,
  }),
)

const StyledTextField = styled(TextField)(
  ({theme}) => ({
    '& .MuiFormLabel-root': {
      backgroundColor: theme.main.bgLight,
      paddingLeft: '10px',
      paddingRight: '10px',
      borderRadius: '3px'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.main.bgLight,
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.light.main,
    },
    '& label.Mui-focused': {
      color: 'gray',
    },

  }),
)

const StyledFormControl = styled(FormControl)(
  ({theme}) => ({
    '& .MuiFormLabel-root': {
      backgroundColor: theme.main.bgLight,
      paddingLeft: '10px',
      paddingRight: '10px',
      borderRadius: '3px'
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: `${theme.palette.light.main} !important`
    },
    '& .MuiFormHelperText-root': {
      fontSize: '1.4rem',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.main.bgLight,
    },
  }),
)

const StyledField = styled(Field)(
  ({theme}) => ({
    '& label.Mui-focused': {
      color: 'gray',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.secondary.main} !important`,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '1.4rem',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.main.bgLight,
    },

  }),
)

const StyledButton = styled(Button)(
  ({theme}) => ({
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
    }
  }),
)


const KYCAnonymous = () => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(0);

  const initialValues = {
    forename : '',
    surname: '',
    title: '',
    email: '',
    recaptcha: ''
  };

  const KYCSchema = Yup.object().shape({
    forename: Yup.string().required('Fist Name is required'),
    surname: Yup.string().required('Last Name is required'),
    title: Yup.string().required('Title is required'),
    email: Yup.string().email("Email must be a valid email").required('Email is required'),
    recaptcha: Yup.string().required("ReCaptcha is required")
  });

  const handleSubmit = async (values) => {
    delete values['recaptcha']
    await dispatch(submitAnonymousKYC(values)).unwrap();
    enqueueSnackbar("KYC verification request has been submitted", { variant: 'success' })
    setStage(2)
  }

  return (
    <ProfileBoxWrapper>
      <ProfileBox>
        <Box mb={3} textAlign="center">
          <img src={Logo} alt="Portfolio Platform" width={200} />
          <Typography variant="h3" color={"white"}>Due diligence and KYC Verification</Typography>
        </Box>

          <Box hidden={ stage === 2} sx={{mb: 3}}>
            Please note that your request will be forwarded to our Compliance department. They will process our regulatory Due diligence and KYC verification.<br/>
            This process is essential to verify your identity and maintain the integrity of our services. Subsequently, you will receive an email from our Back Office.<br/>
            This email will contain detailed instructions on how to proceed with the verification process.<br/>
            <a href="#" onClick={() => setStage(0)} hidden={stage === 0}>Read More</a>
          </Box>

        <Collapse in={stage === 0}>
          <ol>
            <li>Follow the instructions in the email to complete your application. This may include submitting personal information and relevant documents as required by our Compliance department.</li>
            <li>Once you submit your information, our Compliance will review your submission. The review process usually takes 24 to 48 hours upon receipt of your documents, after which your account status will be updated.</li>
            <li>Upon successful verification, you will be notified, and your account will be fully activated.</li>
          </ol>
          <StyledButton sx={{mt: 2}} type="submit" variant="contained" color="secondary" fullWidth  onClick={() => setStage(1)}> Continue </StyledButton>
        </Collapse>

        <Collapse in={stage === 1}>
          <Formik
            sx={{ mt: 3 }}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={KYCSchema}
          >
            {({ handleSubmit, isSubmitting, errors, touched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <StyledField component={StyledTextField} name="forename" label="First Name" fullWidth   />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledField component={StyledTextField} name="surname" label="Last Name" fullWidth  />
                  </Grid>

                  <Grid item xs={12}>
                    <StyledFormControl fullWidth  >
                      <StyledField component={SelectInput} id="title" name="title" label="Title">
                          <MenuItem value="Mr">Mr</MenuItem>
                          <MenuItem value="Mrs">Mrs</MenuItem>
                          <MenuItem value="Ms">Ms</MenuItem>
                          <MenuItem value="Miss">Miss</MenuItem>
                          <MenuItem value="Dr">Dr</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </StyledField>
                    </StyledFormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledField component={StyledTextField} name="email" label="Email Address" fullWidth  />
                  </Grid>

                   <Grid item xs={12}>
                     <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={(value) => setFieldValue('recaptcha', value)}/>
                     {errors.recaptcha && touched.recaptcha ? (
                       <Typography component="p" fontSize="small" color="error.dark" sx={{ pl: 1.5 }}>{errors.recaptcha}</Typography>
                     ) : null}
                   </Grid>

                  <Grid item xs={12} >
                    <StyledButton sx={{mt: 2}} type="submit" variant="contained" color="secondary" fullWidth endIcon={isSubmitting? <CircularProgress size={20} color="secondary" /> :  <UploadIcon/>} disabled={isSubmitting}> REQUEST KYC VERIFICATION </StyledButton>
                  </Grid>
                </Grid>

              </Form>
            )}
          </Formik>
        </Collapse>

        <Collapse in={stage === 2}>
           <Box mb={3} textAlign="center">
              <Typography variant="h4" color={"white"}>Thank you for request.</Typography>
              Please keep an eye out for a follow up email from us shortly.
           </Box>

        </Collapse>
      </ProfileBox>
    </ProfileBoxWrapper>
  );
};

export default KYCAnonymous;
