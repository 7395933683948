import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import OrdersTable from "components/molecules/WebTrader/OrdersTable";
import PositionsTable from "components/molecules/WebTrader/PositionsTable";
import TabPanel from "components/atoms/TabPanel/TabPanel";
import { 
  selectAllOrders, 
  fetchOrders, 
  deleteOrder 
} from 'redux/order/orderSlice';
import { fetchTrades, selectActiveTrades } from 'redux/trade/tradeSlice';

const PositionsOrders = ({strategyId}) => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const orders = useSelector(selectAllOrders);
  const trades = useSelector(selectActiveTrades);
  
  // Initial fetch of orders for tab label
  
  useEffect(() => {
    dispatch(fetchOrders({ strategyId }));
    dispatch(fetchTrades({ strategyId }));
  }, [dispatch, strategyId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteOrder = (orderId) => {
    dispatch(deleteOrder({ strategyId, orderId }));
  };

  const activeOrders = orders?.filter(order => order.active) || [];
  const totalOrders = orders?.length || 0;
  const totalTrades = trades?.length || 0;

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={`Positions (${totalTrades})`} />
        <Tab label={`Orders (${activeOrders.length}/${totalOrders})`} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <PositionsTable 
          trades={trades} 
          strategyId={strategyId}
          isActive={value === 0}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrdersTable 
          orders={orders} 
          onDeleteOrder={handleDeleteOrder}
          strategyId={strategyId}
          isActive={value === 1}
        />
      </TabPanel>
    </>
  );
};

export default PositionsOrders;
