import { configureStore } from '@reduxjs/toolkit';
import strategyReducer from 'redux/strategy/strategySlice';
import globalSlideReducer from 'redux/global/globalSlice';
import subscriptionReducer from 'redux/subscription/subscriptionSlice';
import watchListReducer from 'redux/watchlist/watchListSlice';
import simulationReducer from 'redux/simulation/simulationSlice';
import kycReducer from 'redux/kyc/kycSlice';
import tradeReducer from "redux/trade/tradeSlice";
import orderReducer from "redux/order/orderSlice";
import linkedAccountSlice from "redux/linkedAccount/linkedAccountSlice";
import  userProfileSlice from "redux/userProfile/userProfileSlice";
import NotificationMiddleware from "utils/NotificationMiddleware";

export default configureStore({
  reducer: {
    global: globalSlideReducer,
    userProfile: userProfileSlice,
    linkedAccount: linkedAccountSlice,
    strategy: strategyReducer,
    subscription: subscriptionReducer,
    simulation: simulationReducer,
    watchList: watchListReducer,
    trade: tradeReducer,
    order: orderReducer,
    kyc: kycReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(NotificationMiddleware),
  devTools: process.env.REACT_APP_HOST_ENV === 'development',
});
