import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import TabPanel from "components/atoms/TabPanel/TabPanel";
import TradePanel from "components/molecules/WebTrader/TradePanel";
import { useSelector } from 'react-redux';
import { selectStrategyById } from "redux/strategy/strategySlice";

const TradeTabs = ({strategyId}) => {
  const strategy = useSelector((state) => selectStrategyById(state, strategyId));

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Ensure strategy.trade_type is available and is an array
  const tradeTypes = strategy?.trade_type || [];

  return (
    <Box>
      {/* Dynamically generate tabs */}
      <Tabs value={value} onChange={handleChange} aria-label="trade tabs" variant="fullWidth" centered>
        {tradeTypes.map((type, index) => (
          <Tab key={index} label={type} />
        ))}
      </Tabs>

      {/* Dynamically generate TabPanel content */}
      {tradeTypes.map((type, index) => (
        <TabPanel key={index} value={value} index={index}>
            <TradePanel tradeType={type} strategyId={strategyId}/>
        </TabPanel>
      ))}
    </Box>
  );
};

export default TradeTabs;
