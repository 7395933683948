import React, { useEffect, useRef } from 'react';
import webSocketManager from 'utils/WebSocketManager';
import authAxios from 'utils/authAxios';
import {useAuth0} from "@auth0/auth0-react";
import { useWebTrader } from 'utils/WebTraderProvider';
import { Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const TradingViewChart = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const chartContainerRef = useRef(null);
  const widgetRef = useRef(null);
  const aggregator = useRef(null);
  const isWebSocketConnected = useRef(false);
  const marketDataRef = useRef(null);
  const lastUpdateRef = useRef(0);
  const subscriptionIntervalRef = useRef(null);

  const { 
    selectedSymbol,
    marketData,
  } = useWebTrader();

  // Watch for market data updates
  useEffect(() => {
    if (selectedSymbol?.conid) {
      const symbolMarketData = marketData[selectedSymbol.conid];
      if (symbolMarketData?._updated && symbolMarketData._updated > lastUpdateRef.current) {
        marketDataRef.current = symbolMarketData;
        lastUpdateRef.current = symbolMarketData._updated;
      }
    }
  }, [marketData, selectedSymbol]);

  const fetchHistoricalData = async (resolution, from, to) => {
    // Convert resolution to bar format
    const bar = resolution.includes('d') ? '1day' :
               resolution.includes('w') ? '1week' :
               resolution.includes('m') ? '1month' :
               `${resolution}min`;  // for 1, 5, 30, 60 minutes

    // Calculate period based on time range
    const diffInMs = (to - from) * 1000;
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    
    let period = '1d'; // default period
    if (diffInDays > 90) period = '1y'
    else if (diffInDays > 30) period = '3m';
    else if (diffInDays > 5) period = '1m';
    else if (diffInDays > 1) period = '5d';

    console.log('Fetching data with:', { 
      resolution,
      bar, 
      period, 
      from: new Date(from * 1000), 
      to: new Date(to * 1000), 
      diffInDays 
    });

    try {
      const response = await authAxios.get(`/broker/interactive_brokers/mdh`, {
        params: {
          conid: selectedSymbol?.conid,
          bar,
          period,
          outside_rth: true,
        }
      });

      return response.data.map(bar => ({
        time: bar.t,
        open: Number(bar.o),
        high: Number(bar.h),
        low: Number(bar.l),
        close: Number(bar.c),
        volume: Number(bar.v)
      }));
    } catch (error) {
      console.error('Error fetching historical data:', error);
      return [];
    }
  };

  const getIntervalInMs = (resolution) => {
    if (resolution.includes('d')) return 24 * 60 * 60 * 1000;
    if (resolution.includes('w')) return 7 * 24 * 60 * 60 * 1000;
    if (resolution.includes('m')) return 30 * 24 * 60 * 60 * 1000;
    return parseInt(resolution) * 60 * 1000; 
  };

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const widgetOptions = {
      symbol: selectedSymbol? selectedSymbol.name : 'N/A',
      interval: '5',
      supported_resolutions: ['1', '5', '30', '60', '1d', '1w', '1m'],
      time_frames: [
        { text: "1y", resolution: "1W", description: "1 year in 1 week intervals" },
        { text: "3m", resolution: "60", description: "3 months in 1 hour intervals" },
        { text: "1m", resolution: "30", description: "1 month in 30 minute intervals" },
        { text: "5d", resolution: "5", description: "5 days in 5 minute intervals" },
        { text: "1d", resolution: "1", description: "1 day in 1 minute intervals" },
      ],
      container: chartContainerRef.current,
      library_path: `${process.env.REACT_APP_CHARTING_LIBRARY_PATH}/`,
      width: '100%',
      height: 500, 
      datafeed: {
        onReady: async (callback) => {
          console.log('onReady called');
          callback({
            // supported_resolutions: ["5", "1", "15"]
          });
        },
        
        resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
          setTimeout(() => {
            onSymbolResolvedCallback({
              name: symbolName,
              ticker: symbolName,
              session: '24x7',
              timezone: 'Etc/UTC',
              minmov: 1,
              pricescale: 100,
              has_intraday: true,
              intraday_multipliers: ['1', '5', '15', '30', '60'],
              supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'],
              onIntervalChanged: (interval) => {
              },
              volume_precision: 2,
              data_status: 'streaming',
            });
          }, 0);
        },
        getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
          try {
            // Reinitialize aggregator with new interval based on resolution
            const intervalMs = getIntervalInMs(resolution);
            aggregator.current = new DataAggregator(intervalMs);

            const data = await fetchHistoricalData(resolution, periodParams.from, periodParams.to);
            const filteredBars = data.filter(bar => 
              bar.time >= periodParams.from * 1000 && bar.time <= periodParams.to * 1000
            );
            
            // Tell the chart if we have data or not
            const noData = filteredBars.length === 0;
            
            onHistoryCallback(filteredBars, {
              noData,
              nextTime: noData ? undefined : periodParams.from
            });

          } catch (error) {
            console.error('Error in getBars:', error);
            onErrorCallback(error);
          }
        },
        subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID) => {
          subscriptionIntervalRef.current = setInterval(() => {
            if (selectedSymbol?.conid) {
              const price = parseFloat(marketDataRef.current?.bid_price || 0);
              if (price > 0) {
                aggregator.current.aggregateTick(marketDataRef.current);
                const bars = aggregator.current.getBars();
                if (bars.length > 0) {
                  const lastBar = bars[bars.length - 1];
                  onRealtimeCallback(lastBar);
                }
              }
            }
          }, 1000);

          return subscriptionIntervalRef.current;
        },
        unsubscribeBars: (subscriberUID) => {
          if (subscriptionIntervalRef.current) {
            clearInterval(subscriptionIntervalRef.current);
            subscriptionIntervalRef.current = null;
          }
        },
      },
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'header_settings',
        'header_chart_type',
        'header_compare',
        'header_undo_redo',
        'header_screenshot',
        'header_saveload',
        'drawing_templates',
        'show_chart_property_page',
        'scales_context_menu',
        'legend_context_menu',
        'header_indicators', // Remove indicators button
        'control_bar', // Remove bottom control bar
        'header_widget',
        'go_to_date', // This removes the "Go to" button
        'adaptive_logo', // Optional: removes the TV logo
      ],
      enabled_features: [
        'hide_left_toolbar_by_default',
        'move_logo_to_main_pane',
      ],
    };

    const widget = new window.TradingView.widget(widgetOptions);
    widgetRef.current = widget;

    // Only disconnect WebSocket when component unmounts
    return () => {
      if (subscriptionIntervalRef.current) {
        clearInterval(subscriptionIntervalRef.current);
        subscriptionIntervalRef.current = null;
      }
      webSocketManager.disconnect();
      isWebSocketConnected.current = false;
    };
  }, [selectedSymbol]);

  // If no symbol is selected, show the placeholder message
  if (!selectedSymbol) {
    return (
      <Box 
        sx={{ 
          height: 500, 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center',
          color: 'text.secondary'
        }}
      >
        <SearchIcon sx={{ fontSize: 48, mb: 2, opacity: 0.5 }} />
        <Typography variant="h6" component="div">
          Please select a symbol
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Use the search box to find and select a trading symbol
        </Typography>
      </Box>
    );
  }

  return <div ref={chartContainerRef} />;
};


class DataAggregator {
  constructor(interval) {
    this.interval = interval;
    this.currentBar = null;
    this.bars = [];
    this.lastIntervalStart = null;
  }

  aggregateTick(tickData) {
    const price = parseFloat(tickData?.bid_price || 0);
    const timestamp = tickData?._updated || Date.now();

    // Calculate the interval boundaries for this tick
    const currentIntervalStart = Math.floor(timestamp / this.interval) * this.interval;
    const currentIntervalEnd = currentIntervalStart + this.interval;
    const secondsUntilNextBar = Math.round((currentIntervalEnd - timestamp) / 1000);
    const shouldCreateNewBar = !this.currentBar || currentIntervalStart > this.lastIntervalStart;

    // Create new bar if we don't have one or if we've moved to a new interval
    if (shouldCreateNewBar) {
      if (this.currentBar) {
        this.bars.push({ ...this.currentBar });
      }
      
      this.currentBar = {
        time: currentIntervalStart,
        open: price,
        high: price,
        low: price,
        close: price,
      };
      this.lastIntervalStart = currentIntervalStart;
    } else if (price > 0) {
      // Update existing bar
      this.currentBar.high = Math.max(this.currentBar.high, price);
      this.currentBar.low = Math.min(this.currentBar.low, price);
      this.currentBar.close = price;
    }
  }

  getBars() {
    return [...this.bars, this.currentBar].filter(Boolean);
  }
}

export default TradingViewChart;
