import React from "react";
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import {Card, Grid} from '@mui/material';
import Box from "@mui/material/Box";
import PositionsOrders from "components/organisms/WebTrader/PositionsOrders";
import TradeTabs from "components/organisms/WebTrader/TradeTabs";
import {useParams} from "react-router-dom";



export default function WebTrader() {

  const params = useParams();
  const strategyId = params.strategyId;

  return (
    <>
      <PageHeader text1={"Web"} text2={"Trader"}/>

      <Grid container spacing={2} sx={{mt:1}}>
        <Grid item xs={12} sm={8}>
          <Card sx={{p: 3, mb: 2}}>
            Test
          </Card>
          <Card sx={{p: 3}}>
            <PositionsOrders />
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{p: 3, pt:1}}>
            <Box>
              <TradeTabs strategyId={strategyId} />
            </Box>
            <Box sx={{ pt: 4 }}>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}